import styled from "@emotion/styled";
import { Card, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";

const PartsList = ({ productName }) => {
  const theme = useTheme();

  const StyledCard = styled(Card)({
    textAlign: "center",
    backgroundColor: "#f5f5f5",
    boxShadow: "none",
    marginBottom: "5px",
    background: "linear-gradient(to bottom right, #f2f2f2, rgba(240,240,240, 0.1))",
  });

  const PartNumber = styled(Typography)({
    color: "#333",
    textAlign: "left",
    fontSize: "12px",
    position: "relative",
    minHeight: "60px",
    fontSize: "10px",
  });

  const LineNo = styled(Typography)(({ theme }) => ({
    fontSize: "9px",
    padding: "3px 6px",
    backgroundColor: `${theme.palette.primary.main}`,
    borderRadius: "0 4px 0 0",
    display: "inline-block",
    color: "#fff",
    position: "absolute",
    top: "0",
    right: "0",
    minWidth: "54px",
  }));

  const [parts, setParts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.proluxe.com/parts/${productName}/sellable`
        );
        if (response.data && Array.isArray(response.data)) {
          // Sort the parts by the 'line_no' property
          const sortedParts = response.data.sort(
            (a, b) => a.line_no - b.line_no
          );
          setParts(sortedParts);
        }
      } catch (error) {
        console.error("Failed to fetch parts:", error);
      }
    };
    fetchData();
  }, [productName]);

  return (
    <div>
      <Typography variant="subtitle1" paddingBottom={2}>
        PARTS LIST:
      </Typography>
      {parts.map((part, index) => {
        const match = part.name.split('(');
        const extractedName = match && match.length > 1 ? match[1] : 'N/A';
        const extractedDesc = match && match.length > 2 ? match[2].replace(/\)+$/, "") : 'N/A';
        return (
          <StyledCard key={index}>
            <PartNumber padding={2}>
              <Typography>{extractedName}</Typography>
              <Typography variant="string"><i>{extractedDesc}</i></Typography>
              <LineNo>Line: {part.line_no}</LineNo>
            </PartNumber>
          </StyledCard>
        );
      })}
    </div>
  );
};

export default PartsList;
