import styled from "@emotion/styled";
import {
  Drawer,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Dashboard, FindInPage, Home, Menu, Search } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navigation = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const NavWrapper = styled("div")({
    display: "flex",
    alignItems: "center",
    position: "fixed",
    top: "0",
    width: "100%",
    height: "64px",
    backgroundColor: "#222B36",
    zIndex: "99",
    color: "#fff",
  });

  const Logo = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "28px",
    marginLeft: "20px",
  });

  const SearchBar = styled(InputBase)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    marginRight: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
    position: "absolute",
    right: "0",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.25)",
    },
    color: "#fff",
  }));

  const DrawerStyled = styled(Drawer)({
    "& .MuiPaper-root": {
      backgroundColor: 'rgba(255,255,255,0.5)',
      minWidth: "300px",
    },
  });

  const ListStyled = styled(List)({
    backgroundColor: "#fff",
  });

  return (
    <>
      <NavWrapper>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ ml: 2 }}
          onClick={toggleDrawer}
        >
          <Menu />
        </IconButton>
        <Logo>
          <img

            src="/proluxe-logo-white.png"
            height="80%"
            style={{ marginRight: "10px" }}
            alt="Logo"
          />
          Portal
        </Logo>
        <SearchBar
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          startAdornment={<Search />}
        />
      </NavWrapper>
      <DrawerStyled anchor="left" open={open} onClose={toggleDrawer}>
        <ListStyled>
          <ListItem component={Link} to="/" onClick={toggleDrawer}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button component={Link} to="/serial" onClick={toggleDrawer}>
            <ListItemIcon>
              <FindInPage />
            </ListItemIcon>
            <ListItemText primary="Serial Lookup" />
          </ListItem>
          <ListItem button component={Link} to="/orders/49123.1/tracking" onClick={toggleDrawer}>
            <ListItemIcon>
              <FindInPage />
            </ListItemIcon>
            <ListItemText primary="Order Tracking" />
          </ListItem>
        </ListStyled>
      </DrawerStyled>
    </>
  );
};

export default Navigation;
