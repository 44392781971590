import styled from "@emotion/styled";
import { Container, Grid, Typography, Link, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PartsList from "../Products/PartsList";

const SerialShowPage = () => {
  const theme = useTheme();

  const MoreGrid = styled(Grid)(({ theme }) => ({
    borderColor: `${theme.palette.text.disabled} !important`,
    padding: `0 ${theme.spacing(2)}`,
    // border: "1px solid",
  }));

  const GridList = styled(Grid)(({ theme }) => ({
  }));

  const GridListItem = styled(Typography)(({ theme }) => ({
    fontSize: '12px',
  }));

  const SerialImage = styled("img")({
    borderRadius: "15px",
    maxWidth: "100%",
  });

  const DetailLine = styled("div")({
    marginBottom: theme.spacing(1),
  });

  const TechSupportWrapper = styled("div")({
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
  });

  const SerialActivityWrapper = styled("div")({
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
  });

  const { serial } = useParams();
  const [data, setData] = useState({});
  const [productName, setProductName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `https://api.proluxe.com/serial/${serial}`
      );
      setData(response.data);
      setProductName(response.data.name.split(" ")[0]);
    };
    fetchData();
  }, [serial]);

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <Grid container>
            <Box sx={theme.globals.BoxStyle}>
              <Typography variant="subtitle1">EQUIPMENT</Typography>
              <Grid container>
                <Grid xs={12} sm={12} md={5}>
                  <SerialImage
                    className="serial-image"
                    src="https://proluxe.com/static/86f33e11b5eafdf69d2ebf6aa250ded4/1f5c5/Proluxe-Apex-Pro-X2-DP2350-pizza-dough-press-1.webp"
                    alt="Placeholder"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={7} marginBottom={theme.spacing(4)}>
                  <DetailLine>
                    <Typography variant="body2">Serial No.:</Typography>
                    <Typography variant="">{serial}</Typography>
                  </DetailLine>
                  <DetailLine>
                    <Typography variant="body2">MODEL No.:</Typography>
                    <Typography variant="">{data.name}</Typography>
                  </DetailLine>
                  <DetailLine>
                    <Typography variant="body2">ELECTRICAL INFO:</Typography>
                    <Typography variant="h6">{data.electrical_info}</Typography>
                  </DetailLine>              
                  <DetailLine variant="h6">
                    <Typography variant="body2">PLUG:</Typography>
                    <Typography variant="h6">{data.plug_type}</Typography>
                  </DetailLine>
                  <DetailLine>
                    <Typography variant="body2">DATE SOLD:</Typography>
                    <Typography variant="">{data.date_sold}</Typography>
                  </DetailLine>
                </Grid>
              </Grid>
              {productName && <PartsList productName={productName} />}
            </Box> 
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <MoreGrid>
          <Box sx={theme.globals.BoxStyle}>
              <Typography variant="subtitle1">Tech Support</Typography>
              <TechSupportWrapper>
                <GridList>
                  <GridListItem>
                    Email: <Link href="mailto:info@proluxe.com">info@proluxe.com</Link>
                  </GridListItem>
                  <GridListItem>
                    Phone: <Link href="tel:1231231234">(123)123-1234</Link>
                  </GridListItem>
                </GridList>
              </TechSupportWrapper>
            </Box>
            <Box sx={theme.globals.BoxStyle}>
              <Typography variant="subtitle1">Serial Activity</Typography>
              <SerialActivityWrapper>
                <GridList>
                  <GridListItem>No activity</GridListItem>
                </GridList>
              </SerialActivityWrapper>
            </Box>
          </MoreGrid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SerialShowPage;

