import { createTheme } from "@mui/material";

const spacing = "24px";

const theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#658DC8"
        },
        secondary: {
            main: "#000",
        },
    },
    spacing: 8,
    typography: {
        color: '#6d6d6d',
        allVariants: {
            fontFamily: `"Lato", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important`,
            color: '#6d6d6d',
        },
        h1: {
            fontWeight: '200',
            fontSize: '4rem',
            color:'#000',
        },
        subtitle1: {
            letterSpacing: '2px',
            borderBottom: "1px solid #e9e9e9",
            paddingBottom: "1rem",
            marginBottom: "1rem",
            lineHeight: "1rem",
            textTransform: 'uppercase',
        }
      },
    globals: {
        root: {
            minHeight:'100vh',
        },
        body: {
            backgroundColor: '#efefef',
            margin: 0,
            minHeight:'100vh',
        },
        BoxStyle: {
            backgroundColor: '#fff',
            border: '2px solid #e9e9e9',
            padding: spacing,
            width: '100%',
        }
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: "contained",
            },
            styleOverrides: {
                root: {
                    borderRadius:0,
                    color:'#fff',
                },
                sizeLarge: {
                    // Styles for Button with size="large"
                    padding: "10px 24px",
                  },
            },
        },
      },
  });

  export default theme;