import { CssBaseline, ThemeProvider, GlobalStyles } from "@mui/material";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Navigation from "./components/Navigation";
import HomePage from "./pages/HomePage";
import SerialShowPage from "./pages/Serials/SerialShowPage";
import SerialLookupPage from "./pages/SerialLookupPage";
import OrderTrackingPage from "./pages/OrderTrackingPage";
import theme from "./theme.js";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={ theme.globals } />
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/serial/:serial" element={<SerialShowPage />} />
          <Route path="/serial" element={<SerialLookupPage />} />
          <Route path="/orders/:order/tracking" element={<OrderTrackingPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
