import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LinearProgress, Typography, Link, Box } from "@mui/material";
import { styled } from "@mui/system";
import HandshakeIcon from "@mui/icons-material/Handshake";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const OrderTrackingPage = () => {
  const [trackingData, setTrackingData] = useState(null);
  const { order } = useParams();

  useEffect(() => {
    const fetchTrackingData = async () => {
      try {
        const response = await fetch(`https://api.proluxe.com/orders/${order}/tracking`);
        if (response.ok) {
          const data = await response.json();
          data[0].tracking_state = "In Transit";
          data[1].tracking_state = "Delivered";
          setTrackingData(data);
        } else {
          // Handle error case
          console.error("Failed to fetch tracking data");
        }
      } catch (error) {
        // Handle error case
        console.error("Error while fetching tracking data", error);
      }
    };

    fetchTrackingData();
  }, [order]);

  const getTrackingService = (trackingNumber) => {
    // Logic to determine the tracking service
    // ...
  };

  const getTrackingLink = (trackingNumber) => {
    // Logic to generate the tracking URL
    // ...
  };

  const renderProgressIcon = (state) => {
    switch (state) {
      case "Picked Up":
        return <HandshakeIcon />;
      case "In Transit":
        return <LocalShippingIcon />;
      case "Exception":
        return <ReportProblemIcon />;
      case "Out for Delivery":
        return <DirectionsCarIcon />;
      case "Delivered":
        return <CheckCircleIcon />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Order Tracking
      </Typography>
      {trackingData ? (
        <OrderList>
          {trackingData.map((trackingItem) => (
            <OrderItem key={trackingItem.id}>
              <Typography variant="body1" fontWeight="bold" color="primary.main" gutterBottom>
                Order Number: {trackingItem.order_no}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Tracking Number:{" "}
                <LinkStyled href={getTrackingLink(trackingItem.tracking_no)} target="_blank" rel="noopener noreferrer">
                  {trackingItem.tracking_no}
                </LinkStyled>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Shipped Quantity: {trackingItem.qty_shipped}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Packaged Quantity: {trackingItem.qty_packaged}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Address: {trackingItem.address}
              </Typography>
              {trackingItem.tracking_state && (
                <Box display="flex" alignItems="center" mt={2}>
                  <ProgressIcon>{renderProgressIcon(trackingItem.tracking_state)}</ProgressIcon>
                  <Typography variant="body2" ml={2}>
                    {trackingItem.tracking_state}
                  </Typography>
                </Box>
              )}
            </OrderItem>
          ))}
        </OrderList>
      ) : (
        <Typography variant="body1">Loading tracking data...</Typography>
      )}
    </Container>
  );
};

const Container = styled("div")`
  padding: 20px;
`;

const OrderList = styled("div")`
  display: grid;
  grid-gap: 20px;
`;

const OrderItem = styled("div")`
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
`;

const LinkStyled = styled(Link)`
  color: #1da1f2;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ProgressIcon = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #1da1f2;
  color: #fff;
  margin-right: 10px;
`;

export default OrderTrackingPage;
