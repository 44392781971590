import React from "react";
import { Container, Typography } from "@mui/material";
import styled from "@emotion/styled";

const HomePageContainer = styled(Container)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  textAlign: "center",
});

const HomePage = () => {
  return (
    <HomePageContainer maxWidth="lg">
      <Typography variant="h3" component="h1">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            className="App-logo"
            style={{ width: "92px" }}
            src="/portal-logo.png"
          />
          PROLUXE PORTAL
        </div>
      </Typography>
    </HomePageContainer>
  );
};

export default HomePage;
