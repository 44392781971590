import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Dialog, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { Send as SendIcon } from "@mui/icons-material";

const SerialLookupPage = () => {
  const [serialNumber, setSerialNumber] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSerialNumberChange = (event) => {
    setSerialNumber(event.target.value);
    setError("");
  };

  const handleLookup = () => {
    if (serialNumber) {
      navigate(`/serial/${serialNumber}`);
    } else {
      setError("Please enter a serial number.");
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const StyledContainer = styled(Container)`
    background-color: #fff;
    padding: 10rem 2rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `;

  return (
    <StyledContainer maxWidth="md" sx={{ margin: '10% auto' }}>
      <Typography variant="h1" align="center" sx={{ marginBottom: '1rem' }}>
        Serial Lookup
      </Typography>
      <Typography>Track your equipment's history and find help</Typography>
      <Box maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '4rem' }}>
        <TextField
          autoFocus
          label="Enter Serial Number ex: 86355"
          variant="outlined"
          value={serialNumber}
          fullWidth
          onChange={handleSerialNumberChange}
          error={!!error}
          helperText={error}
          sx={{ flexBasis: '100%' }}
        />
        <div>
          <Button
            color="secondary"
            startIcon={<SendIcon />}
            onClick={handleLookup}
            sx={{ marginTop: "2rem", }}
            size="large"
          >
            SUBMIT
          </Button>
        </div>
        <Typography variant="" sx={{ display: "block", marginTop: "3rem", cursor: "pointer", textDecoration: "underline" }} onClick={handleOpenModal}>
          Where do I find my serial number?
        </Typography>
        <Dialog open={isModalOpen} onClose={handleCloseModal}>
          <DialogTitle>Serial Number Location</DialogTitle>
          <DialogContent>
            <img src="path_to_your_image" alt="Serial Number Location" />
          </DialogContent>
        </Dialog>
      </Box>
    </StyledContainer>
  );
};

export default SerialLookupPage;
